import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Table,
  Container,
  Pagination,
  Button,
} from "react-bootstrap";
import RenderPickupDate from "./RenderPickupDate";
import RenderCityResult from "./RenderCityResult";
import RenderRate from "./RenderRate";
import RenderDistance from "./RenderDistance";
import RenderPayPerDistance from "./RenderPayPerDistance";
import RenderWeight from "./RenderWeight";
import RenderStatus from "./RenderStatus";
import RenderInternalReferenceNumber from "./RenderInternalReferenceNumber";
import PaginationFyB from "./PaginationFyB";
import ModalDelete from "../../components/Dashboard/Orders/ModalDelete";
import ModalFormUpdate from "../../components/Dashboard/Orders/ModalFormUpdate";
import ModalClone from "../../components/Dashboard/Orders/ModalClone";
import { IoTicketSharp } from "react-icons/io5";
import { FaRegClone, FaMapMarkerAlt } from "react-icons/fa";
import { FiFilePlus, FiShare } from "react-icons/fi";
import { MdEdit, MdDelete, MdModeComment } from "react-icons/md";
import useFetch from "../../hooks/useFetch";
import { BodegasContext } from "../../context";
import RenderOrderAttachment from "../freightpost_public/RenderOrderAttachment";
import ModalAddAttachment from "../../components/Dashboard/Orders/ModalAddAttachment";
import RenderButtonMore from "./RenderButtonMore";

const RenderMyOrders = ({ orders, isMobile, reloadOrders }) => {
  const [showDel, setShowDel] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showClone, setShowClone] = useState(false);
  const [showAddAttachment, setShowAddAttachment] = useState(false);
  const [dataAddAttachment, setDataAddAttachment] = useState(null);
  const [bedTypes, setBedTypes] = useState(null);
  const [points, setPoints] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(null);
  const [dataClone, setDataClone] = useState(null);
  const [dataDelete, setDataDelete] = useState(null);
  const [modalUDT, setModalUDT] = useState(false);
  const [modalClone, setModalClone] = useState(false);
  const { companyId } = useContext(BodegasContext);
  const { fletesAPI } = useFetch();
  const [cities, setCities] = useState(null);

  const enablePublicAccess = async (e) => {
    await fletesAPI
      .post("api/freightpost/" + e.id + "/bucket/")
      .then((e) => {
        reloadOrders("init");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setCities(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPoints = async () => {
    await fletesAPI
      .get(`api/pointofcontact/?company_id=${companyId}`)
      .then((e) => {
        setPoints(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/freightbedoptions/`)
      .then((e) => {
        setBedTypes(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    showUpdate
      ? setModalUDT(true)
      : setTimeout(() => {
          setModalUDT(false);
        }, 200);
  }, [showUpdate]);

  useEffect(() => {
    showClone
      ? setModalClone(true)
      : setTimeout(() => {
          setModalClone(false);
        }, 200);
  }, [showClone]);
  return (
    <>
      {isMobile ? (
        <Container>
          {orders &&
            orders.results.map((e, i) => {
              return (
                <div className="v2_dashboard_body_box">
                  <Row>
                    <RenderStatus status={e.status} isMobile={isMobile} />
                    <RenderPickupDate
                      date={e.ready_pickup_date}
                      isMobile={isMobile}
                    />
                    <RenderCityResult
                      origin_city={e.origin_city}
                      destination_city={e.destination_city}
                      display_city={e.origin_city}
                      isMobile={isMobile}
                      subtitle={"Origen"}
                    />
                    <RenderCityResult
                      origin_city={e.origin_city}
                      destination_city={e.destination_city}
                      display_city={e.destination_city}
                      isMobile={isMobile}
                      subtitle={"Destino"}
                    />
                  </Row>
                  <Row>
                    <RenderDistance distance={e.distance} isMobile={isMobile} />
                    <RenderRate
                      rate={e.rate}
                      currency={e.currency}
                      isMobile={isMobile}
                    />
                    <RenderPayPerDistance
                      rate={e.rate}
                      distance={e.distance}
                      isMobile={isMobile}
                    />
                  </Row>
                  <Row>
                    <RenderInternalReferenceNumber
                      internalReferenceNumber={e.internal_reference_number}
                      isMobile={isMobile}
                    />
                    <RenderWeight weight={e.weight} isMobile={isMobile} />
                  </Row>
                  <Row>
                    {e.attachments &&
                      e.attachments.map((e, i) => {
                        return (
                          <>
                            <RenderOrderAttachment
                              attachment={e}
                              isMobile={isMobile}
                              getMore={reloadOrders}
                            />{" "}
                          </>
                        );
                      })}
                  </Row>
                  <Row>
                    {e.token_for_public_access != undefined && (
                      <>
                        <p>
                          <a
                            href={
                              window.location.origin +
                              "/share/order/" +
                              e.token_for_public_access
                            }
                          >
                            {window.location.origin +
                              "/share/order/" +
                              e.token_for_public_access}
                          </a>
                        </p>
                        <br />
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col xl={3} lg={3} md={6} sm={6}>
                      <p>
                        <a
                          className="dashboard_main_container_result_link"
                          onClick={() => {
                            setShowUpdate(true);
                            getCities();
                            getBedTypes();
                            getPoints();
                            setDataUpdate(e);
                          }}
                        >
                          {e.point_of_contact.first_name}{" "}
                          {e.point_of_contact.last_name}
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={1} lg={1} md={2} sm={2}>
                      {e.token_for_public_access != undefined ? (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            enablePublicAccess(e);
                          }}
                          className="v2_dashboard_body_buttons"
                        >
                          <FiShare />
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            enablePublicAccess(e);
                          }}
                          className="v2_dashboard_body_buttons"
                        >
                          {" "}
                          <FiShare />{" "}
                        </Button>
                      )}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => {
                          setShowAddAttachment(true);
                          setDataAddAttachment(e);
                        }}
                      >
                        {" "}
                        <FiFilePlus />{" "}
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setShowUpdate(true);
                          getCities();
                          getBedTypes();
                          getPoints();
                          setDataUpdate(e);
                        }}
                        className="v2_dashboard_body_buttons"
                      >
                        {" "}
                        <MdEdit />{" "}
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          getBedTypes();
                          setShowClone(true);
                          setDataClone(e);
                        }}
                        className="v2_dashboard_body_buttons"
                      >
                        <FaRegClone />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          setShowDel(true);
                          setDataDelete(e);
                        }}
                        className="v2_dashboard_body_buttons"
                      >
                        <MdDelete />
                      </Button>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1}></Col>
                  </Row>
                </div>
              );
            })}
          <PaginationFyB data={orders} reloader={reloadOrders} />
        </Container>
      ) : (
        <>
          <Row>
            <Col>
              {orders && orders.results.length > 0 ? (
                <>
                  <Table
                    striped
                    bordered
                    className="dashboard_main_container_table"
                  >
                    <thead>
                      <tr>
                        <th>Fecha de Carga</th>
                        <th>Ciudad de origen</th>
                        <th>Ciudad de destino</th>
                        <th>Punto de contacto</th>
                        <th>Numero de referencia</th>
                        <th>Tarifa</th>
                        <th>Estado</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders &&
                        orders.results.map((e, i) => {
                          return (
                            <tr key={i}>
                              <RenderPickupDate
                                date={e.ready_pickup_date}
                                isMobile={isMobile}
                              />
                              <RenderCityResult
                                origin_city={e.origin_city}
                                destination_city={e.destination_city}
                                display_city={e.origin_city}
                                isMobile={isMobile}
                                subtitle={"Origen"}
                              />
                              <RenderCityResult
                                origin_city={e.origin_city}
                                destination_city={e.destination_city}
                                display_city={e.destination_city}
                                isMobile={isMobile}
                                subtitle={"Destino"}
                              />
                              <td>
                                {e.point_of_contact.first_name}{" "}
                                {e.point_of_contact.last_name}
                              </td>
                              <td>{e.internal_reference_number}</td>
                              <RenderRate
                                rate={e.rate}
                                isMobile={isMobile}
                                currency={e.currency}
                              />
                              <RenderStatus
                                isMobile={isMobile}
                                status={e.status}
                              />
                              <td>
                                <RenderButtonMore
                                  buttons={[
                                    {
                                      label: (
                                        <>
                                          Ver archivos <FiFilePlus />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/files`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Publicación <MdEdit />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/public`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Detalles <MdEdit />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/details`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Gastos <IoTicketSharp />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/expenses`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Comentarios <MdModeComment />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/comments`),
                                    },
                                    {
                                      label: (
                                        <>
                                          GPS <FaMapMarkerAlt />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/gps`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Clonar <FaRegClone />
                                        </>
                                      ),
                                      onClick: () => {
                                        getBedTypes();
                                        setShowClone(true);
                                        setDataClone(e);
                                      },
                                    },
                                    {
                                      label: (
                                        <>
                                          Compartir <FiShare />
                                        </>
                                      ),
                                      onClick: () =>
                                        (window.location.href = `/dashboard/order/${e.id}/files`),
                                    },
                                    {
                                      label: (
                                        <>
                                          Eliminar <MdDelete />
                                        </>
                                      ),
                                      onClick: () => {
                                        setShowDel(true);
                                        setDataDelete(e);
                                      },
                                    },
                                  ]}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {orders && (
                    <Pagination>
                      <Pagination.Prev
                        disabled={!orders.previous}
                        onClick={() => reloadOrders(orders.previous)}
                      />
                      <Pagination.Next
                        disabled={!orders.next}
                        onClick={() => reloadOrders(orders.next)}
                      />
                    </Pagination>
                  )}
                </>
              ) : (
                <>
                  <br />
                  <h3 className="text-center fyb_h2">
                    No tienes cargas, crea una con el boton de arriba "Agregar
                    Carga"
                  </h3>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
      <ModalAddAttachment
        show={showAddAttachment}
        setShow={setShowAddAttachment}
        getMore={reloadOrders}
        dataFreightPost={dataAddAttachment}
      />
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reloadOrders}
      />
      {modalUDT && (
        <ModalFormUpdate
          show={showUpdate}
          setShow={setShowUpdate}
          getMore={reloadOrders}
          cities={cities}
          bedTypes={bedTypes}
          points={points}
          dataUpdate={dataUpdate}
        />
      )}
      {modalClone && (
        <ModalClone
          show={showClone}
          setShow={setShowClone}
          getMore={reloadOrders}
          bedTypes={bedTypes}
          points={points}
          toClone={dataClone}
        />
      )}
    </>
  );
};

export default RenderMyOrders;
