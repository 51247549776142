import { useEffect } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import RenderInput from "../../../fyb_components/results/RenderInput";
import RenderSelect from "../../../fyb_components/results/RenderSelect";
import SelectDate from "../../../fyb_components/results/Selectdate";
import SelectFreight from "../SelectFreight";
import moment from "moment";
import toast from "react-hot-toast";
import { fullNameCity } from "../../../utils";

const getOption = (id) => {
  const arr = [
    "Mantenimiento",
    "Limpieza",
    "Reparación",
    "Cita",
    "Flete",
    "Otro",
  ]
  return arr[id] || "Otro";
}

const updateAppointment = yup.object().shape({
  title: yup.string().required("El título es requerido"),
  description: yup.string().required("La descripción es requerida"),
  type: yup.object().required("El tipo es requerido").nullable(),
  start_date: yup.string().required("La fecha de inicio es requerida"),
  end_date: yup.string().required("La fecha de fin es requerida"),
});

const ModalUpdateAppointment = ({ show, handleClose, truckData, id, update, onDelete }) => {
  const { fletesAPI } = useFetch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(updateAppointment),
    defaultValues: {
      title: "",
      description: "",
      type: undefined,
      freight_id: undefined,
      start_date: "",
      end_date: "",
    },
  });
  
  const close = () => {
    handleClose();
    reset();
  };

  const onSubmit = async (inf) => {
    console.log(inf);
    const toastId = toast.loading("Actualizando cita...");
    try {
      await fletesAPI.patch(`api/companytruck/${id}/appointments/`, {
        title: inf.title,
        description: inf.description,
        type: inf.type.value,
        start_date:  moment(inf.start_date).utc().format(),
        end_date: moment(inf.end_date).utc().format(),
        appointment_id: truckData?.id,
      });
      update()
      close();
      toast.dismiss(toastId);
      toast.success("Cita actualizada");
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
      toast.error("Error al actualizar la cita");
    }
  };

  const freight = watch("freight_id");

  useEffect(() => {
    if (Boolean(truckData)) {
      setValue("title", truckData?.data?.title);
      setValue("description", truckData?.data?.description);
      setValue("type", { value: truckData?.data?.type, label: getOption(truckData.data.type) });
      setValue("start_date", new Date(truckData?.start));
      setValue("end_date", new Date(truckData?.end));
      setValue("freight_id", Boolean(truckData?.data?.freight) ? { value: truckData.id, label: `${fullNameCity(truckData?.data?.freight?.quote?.origin_city)} | ${fullNameCity(truckData?.data?.freight?.quote?.destination_city)} | ${truckData?.data?.freight?.quote?.company?.name}` } : undefined);
    }
  }, [truckData]);

  return (
    <Modal show={show} onHide={close}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Cita</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-3">
          <Row>
            <SelectFreight
              name="freight_id"
              control={control}
              error={errors?.freight_id?.message}
              label="Flete"
              placeholder="Seleccione el flete"
            />
            {freight && <a href={`/dashboard/my_order/${freight.value}/edit`} target="_blank" rel="noreferrer">ir a flete</a>}
          </Row>
          <Row>
            <RenderInput
              className=""
              label="Título"
              name="title"
              register={register}
              error={errors?.title?.message}
              placeholder={"Ingrese el título"}
            />
          </Row>
          <Row>
            <RenderInput
              className=""
              label="Descripción"
              name="description"
              register={register}
              error={errors?.description?.message}
              as={"textarea"}
              placeholder={"Ingrese la descripción"}
            />
          </Row>
          <Row>
            <RenderSelect
              className=""
              label="Tipo"
              name="type"
              control={control}
              error={errors?.type?.message}
              options={[
                { value: 0, label: "Mantenimiento" },
                { value: 1, label: "Limpieza" },
                { value: 2, label: "Reparación" },
                { value: 3, label: "Cita" },
                { value: 4, label: "Flete" },
                { value: 5, label: "Otro"}
              ]}
            />
          </Row>
          <Row>
            <Col>
              <SelectDate
                className=""
                name="start_date"
                control={control}
                placeholder="Selecciona la fecha de carga"
                label="Fecha de carga"
              />
            </Col>
            <Col>
              <SelectDate
                  className=""
                name="end_date"
                control={control}
                placeholder="Selecciona la fecha de entrega"
                label="Fecha de entrega"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className=" d-flex justify-content-between">
        <Button variant="danger" className="w-auto" onClick={() => {
          onDelete({data: truckData});
          close();
          }}>
          Eliminar
        </Button>
        <div className="d-flex gap-3">
          <Button
            variant="success"
            type="submit"
          >
            Guardar
          </Button>
        </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalUpdateAppointment;
