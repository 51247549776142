import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Container,
  Image,
  Row,
  Col,
  Card,
  Form,
  Button,
  ToggleButton,
  FormCheck,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyValidation } from "../../../utils/formsValidation";
import Select from "react-select";
import { VscWorkspaceTrusted, VscWorkspaceUntrusted } from "react-icons/vsc";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { citiesStyles } from "../../../utils/styles";
import { BodegasContext } from "../../../context";

export default function Profile() {
  const [profile, setProfile] = useState(null);
  const { companyId } = useContext(BodegasContext);
  const [loading, setLoading] = useState(false);

  const [originCities, setOriginCities] = useState(null);
  const resOriginCities =
    originCities === null
      ? []
      : originCities.map((e) => ({
          value: e.id,
          label: `${e.name}, ${e.state.name}, ${e.state.country.name}`,
        }));
  const [loadOriginCities, setLoadOriginCities] = useState(false);
  const [socialToken, setSocialToken] = useState();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(companyValidation),
  });
  const onSubmit = (data) => {
    updatedProfileToast(data);
  };
  const { fletesAPI } = useFetch();
  const getCompanyProfile = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/company/${companyId}/`);
      setProfile(response.data);
      setValue("address_line1", response.data.contact_information.address_line1);
      setValue("address_line2", response.data.contact_information.address_line2);
      setValue("zip_code", response.data.contact_information.zip_code);
      setValue("phone", response.data.contact_information.phone);
      setValue("name", response.data.contact_information.name);
      setValue("email", response.data.contact_information.email);
      setValue("city", {
        value: response.data.contact_information.city.id,
        label: `${response.data.contact_information.city.name}, ${response.data.contact_information.city.state.name}, ${response.data.contact_information.city.state.country.name}`,
      });
      setSocialToken(response.data.social_token);
      setValue("social_token", response.data.social_token);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const switchSocialToken = async () => {
    if (socialToken) {
      deleteSocialToken();
    } else {
      enableSocialToken();
    }
  };

  const enableSocialToken = async () => {
    var url = `api/company/${companyId}/social/`;
    setLoading(true);
    await fletesAPI
      .post(url)
      .then((e) => {
        toast.success(mensajesAPI("toast_success_saving_message"));
        setSocialToken(e.data.token);
        setLoading(false);
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(mensajesAPI("toast_generic_error"));
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
        setLoading(false);
      });
  };

  const deleteSocialToken = async () => {
    var url = `api/company/${companyId}/social/`;
    setLoading(true);
    await fletesAPI
      .delete(url)
      .then((e) => {
        setLoading(false);
        toast.success(mensajesAPI("toast_success_saving_message"));
        setSocialToken(null);
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(mensajesAPI("toast_generic_error"));
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
        setLoading(false);
      });
  };

  const updateProfile = async (data) => {
    var url = `api/company/${companyId}/contact_information/`;
    await fletesAPI
      .patch(url, {
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        zip_code: data.zip_code,
        phone: data.phone,
        city_id: data.city.value,
        name: data.name,
        email: data.email,
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(mensajesAPI("toast_generic_error"));
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  const updatedProfileToast = (data) => {
    toast.promise(updateProfile(data), {
      loading: "Guardando cambios...",
      success: mensajesAPI("toast_success_saving_message"),
      error: "Error al guardar cambios",
    });
  }

  const getOriginCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setOriginCities(e.data.results);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  

  const updateOriginCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setOriginCities(e.data);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const updateLoadOrigin = (e) => {
    setLoadOriginCities(true);
    setTimeout(() => {
      updateOriginCities(e.target.value);
    }, 400);
  };

  useEffect(() => {
    if (!profile) {
      toast.promise(getCompanyProfile(), {
        success: "Perfil cargado",
        loading: "Cargando perfil...",
        error: "Error al cargar perfil",
      });
    }
  }, [profile]);

  return (
    <>
      <Container className="mt-5">
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            <h1 className="dashboard_main_container_h1 fyb_h1 text-center">
              Mi Compañía
            </h1>
            <br />
            <Row className="gutters">
              <Col xl={4} lg={4} md={12} sm={12}>
                <Card className="h-100">
                  <Card.Body className="card-body">
                    <div className="account-settings">
                      <div className="user-profile">
                        {profile && (
                          <>
                            <h3 className="fyb_h3 text-center">
                              Datos de Certificacion
                            </h3>
                            <br />
                            <p>Nombre: {profile.name}</p>
                            <p>
                              Certificada:{" "}
                              {profile.verified ? (
                                <>
                                  <VscWorkspaceTrusted /> Si{" "}
                                </>
                              ) : (
                                <>
                                  <VscWorkspaceUntrusted /> No
                                </>
                              )}
                            </p>
                            <p>
                              Logística: {Boolean(profile.broker) ? "Si" : "No"}
                            </p>
                            <p>
                              Transportista:{" "}
                              {Boolean(profile.carrier) ? "Si" : "No"}
                            </p>
                            {Boolean(profile.carrier) && (
                              <p>DOT: {profile.dot_code}</p>
                            )}
                            {Boolean(profile.carrier) && (
                              <p>MC/MX: {profile.mc_code}</p>
                            )}
                            {Boolean(profile.carrier) && (
                              <p>C-TPAT: {profile.ctpat_code}</p>
                            )}
                            {Boolean(profile.carrier) && (
                              <p>OEA: {profile.oea_code}</p>
                            )}
                            <p>RFC: {profile.rfc_code}</p>
                            <h5>
                              Para actualizar tu informacion acerca de
                              certificaciones contacta
                              soporte@fletesybodegas.com
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12}>
                <Card className="">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card.Body>
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <h3 className="fyb_h3 text-center">
                            Datos de contacto
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="form">
                          <Form.Label>Nombre completo</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ingrese su nombre completo"
                            {...register("name")}
                            isInvalid={errors.address_line2}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="form">
                          <Form.Label>Correo</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ingrese su correo electronico"
                            {...register("email")}
                            isInvalid={errors.address_line2}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="form">
                          <Form.Label>Telefono</Form.Label>
                          <PhoneInputWithCountry
                            name="phone"
                            placeholder="Ingrese su número telefónico"
                            defaultCountry="MX"
                            control={control}
                            rules={{ required: true }}
                          />
                          {errors.phone && (
                            <p className="dashboard_form_error_message">
                              {mensajesFormulario("phone_invalid_format")}
                            </p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          className="mb-3"
                          controlId="formCompanyName"
                        >
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ingrese su dirección"
                            {...register("address_line1")}
                            isInvalid={errors.address_line1}
                          />
                          {errors.address_line1 && (
                            <p className="dashboard_form_error_message">
                              {mensajesFormulario("address_line1_required")}
                            </p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="form">
                          <Form.Label>Colonia</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ingrese su colonia"
                            {...register("address_line2")}
                            isInvalid={errors.address_line2}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="formCityOrgin">
                          <Form.Label>Ciudad</Form.Label>
                          <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                              <Select
                                isLoading={loadOriginCities}
                                styles={citiesStyles}
                                isClearable
                                placeholder="Seleccione su ciudad de origen"
                                {...field}
                                options={resOriginCities}
                                onFocus={() => {
                                  getOriginCities();
                                  setLoadOriginCities(true);
                                }}
                                onKeyDown={(e) => {
                                  e.target.value
                                    ? e.target.value.length > 2
                                      ? updateLoadOrigin(e)
                                      : setTimeout(() => {
                                          getOriginCities();
                                        }, 50)
                                    : setTimeout(() => {
                                        getOriginCities();
                                      }, 50);
                                }}
                              />
                            )}
                          />
                          {errors.city && (
                            <p className="dashboard_form_error_message">
                              {mensajesFormulario("city_required")}
                            </p>
                          )}
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="form">
                          <Form.Label>Código Postal</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Código Postal"
                            {...register("zip_code")}
                            isInvalid={errors.zip_code}
                          />
                          {errors.zip_code && (
                            <p className="dashboard_form_error_message">
                              {mensajesFormulario("zip_code_required")}
                            </p>
                          )}
                        </Form.Group>
                      </Row>
                      <Button type="submit" size="sm" className="text-light">
                        Guardar Cambios
                      </Button>
                    </Card.Body>
                  </Form>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}
