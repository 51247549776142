import { Button, Container, Row, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { MdCheckCircle } from "react-icons/md";
import { fullNameCity } from "../../../utils";
import { formatPhoneNumber } from "react-phone-number-input";

const FinishQuote = ({ restart, finishQuote }) => {
  console.log({ finishQuote });
  return (
    <Container>
      <Row className="justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">
          <h1 className="m-0">Reserva Ya! - Disponibilidad Limitada</h1>
          <h2>${finishQuote?.total || "Error"} {finishQuote?.currency || "Error"}</h2> 
          <Card style={{ width: "600px" }}>
            <Card.Body className="gap-2 d-flex flex-column">
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p><b>Informacion del Transportista</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Compañia:</b> {finishQuote?.transportation_company?.name || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Nombre:</b> {finishQuote?.transportation_company?.contact_information?.name || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Telefono:</b> {formatPhoneNumber(finishQuote?.transportation_company?.contact_information?.phone) || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Correo:</b> {finishQuote?.transportation_company?.contact_information?.email || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Direccion:</b> {finishQuote?.transportation_company?.contact_information?.address_line1 || ""}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item>
                  <div className="header-card_section">
                    <p><b>Informacion de estimacion</b></p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Camion:</b> {finishQuote?.bed_type?.name || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Origen:</b> {fullNameCity(finishQuote?.origin_city) || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Destino:</b> {fullNameCity(finishQuote?.destination_city) || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Fecha y hora de recolección:</b> {moment.utc(finishQuote?.pickup_time).format("YYYY-MM-DD HH:mm").toString() || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Fecha y hora de entrega:</b> {moment.utc(finishQuote?.delyver_time).format("YYYY-MM-DD HH:mm").toString() || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Tarimas:</b> {finishQuote?.pallets || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Peso:</b> {finishQuote?.weight || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Carga:</b> {finishQuote?.cargo || ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Precio:</b> ${finishQuote?.total || "Error"} {finishQuote?.currency || "Error"}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <Button
            type="submit"
            className="fyb_large_button text-white"
            style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
            onClick={restart}
          >
            Crear nueva cotización
          </Button>
          <a
            onClick={() => (window.location.href = "/dashboard/my_quotes")}
            className="link"
            style={{cursor: "pointer"}}
          >
            Ver Mis estimaciones
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default FinishQuote;
