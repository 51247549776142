import { useState, useEffect } from "react";
import { Row, Spinner, Button, Container } from "react-bootstrap";
import { RiAddLine } from "react-icons/ri";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../utils";
import RenderMyQuotes from "../../../fyb_components/results/RenderMyQuotes";
import ModalCreateQoute from "./ModalCreateQoute";
import QoutesTable from "./QoutesTable";

export default function MyQuotes() {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const [myQuotes, setMyQuotes] = useState(null);
  const [isMobile, setIsMobile] = useState(null);
  const [showMCQ, setShowMCQ] = useState(false);

  const getMyQuotes = async () => {
    await fletesAPI
      .get("api/quote/")
      .then((e) => {
        setMyQuotes(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const addQuote = () => {
      window.location.href = "/dashboard/quotes/create";
  }

  useEffect(() => {
    if (myQuotes === null) {
      toast.promise(getMyQuotes(), {
        loading: "Cargando estimaciones...",
        success: "Estimaciones cargadas",
        error: "Error al cargar estimaciones",
      });
    }
  }, [myQuotes]);

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Estimaciones</h1>
          <Button
            onClick={addQuote}
            variant="primary"
            className="text-white"
            style={{ width: "220px" }}
          >
            <RiAddLine /> Agregar estimacion
          </Button>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <QoutesTable
            quotes={myQuotes}
            title={""}
            isMobile={isMobile}
            reloadOrders={getMyQuotes}
          />
        )}
        <ModalCreateQoute
          show={showMCQ}
          setShow={setShowMCQ}
          getMore={getMyQuotes}
        />
      </Container>
    </>
  );
}
