import { Form } from "react-bootstrap";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";

const RenderInputPhone = ({
  label,
  placeholder,
  defaultCountry = "MX",
  control,
  name,
  required = false,
  errors,
  className = "mb-4"
}) => {
  return (
    <Form.Group className={className}>
      <Form.Label htmlFor="phone">{label}</Form.Label>
      <PhoneInputWithCountrySelect
        name={name}
        isInvalid={errors}
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        control={control}
        rules={{ required }}
        // inputComponent={{className: "form-control"}}
      />
      <p className="text-danger m-0 form-text position-absolute">{errors}</p>
    </Form.Group>
  );
};

export default RenderInputPhone;
