import React from "react";
import { Container, Col, Row, Nav } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs'


//assets
import { ReactComponent as Logo } from "../assets/icons/logo-white.svg";

function Footer() {
  return (
    <footer style={{background: '#0F2840'}}>
      <Container className="py-5">
        <Row>
          <Col>
            <Logo style={{width: '100px'}} />
            <p className=" text-light py-4">Visita nuestras redes sociales y descubre novedades sobre nuestra empresa</p>
            <Row>
              <Col className='p-0'> <BsFacebook style={{color: '#fff', width: '24px', height: '24px'}} /> </Col>
              <Col className='p-0'> <BsInstagram style={{color: '#fff', width: '24px', height: '24px'}} /> </Col>
              <Col className='p-0'> <BsLinkedin style={{color: '#fff', width: '24px', height: '24px'}} /> </Col>
              <Col className='p-0'> <BsTwitter style={{color: '#fff', width: '24px', height: '24px'}} /> </Col>
            </Row>
          </Col>
          <Col>
            <h4 className="text-light mb-4">Enlaces Rápidos</h4>
            {/*
            <Nav.Link href="#product" className="text-light px-0 py-2">Producto</Nav.Link>
            <Nav.Link href="/pricing" className="text-light px-0 py-2">Precios</Nav.Link>
            */}
            <Nav.Link href="/support" className="text-light px-0 py-2">Soporte</Nav.Link>
            <Nav.Link href="https://soporte.fletesybodegas.com" className="text-light px-0 py-2">Blog</Nav.Link>
            <Nav.Link href="https://app.termly.io/document/privacy-policy/ed1dfc48-4115-4a79-8c26-fc1388e7e265" className="text-light px-0 py-2">Aviso de Privacidad</Nav.Link>
            <Nav.Link href="https://app.termly.io/document/terms-of-use-for-saas/f7714dad-7b92-43d4-abae-acc82994f9b1" className="text-light px-0 py-2">Términos y Condiciones</Nav.Link>
          </Col>
          <Col>
            <p className=" text-light py-4">Todos los derechos reservados a Fletes y Bodegas LLC</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
