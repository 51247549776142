import { useState, useEffect } from "react";
import { Row, Spinner, Container } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../utils";
import MyOrderTable from "./MyQoutesTable";

export default function MyQuotes() {
  const { fletesAPI } = useFetch();
  const [loading, setLoading] = useState(false);
  const [myQuotes, setMyQuotes] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  const getMyQuotes = async () => {
    await fletesAPI
      .get("api/quote/")
      .then((e) => {
        setMyQuotes(e.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (myQuotes === null) {
      toast.promise(getMyQuotes(), {
        loading: "Cargando estimaciones...",
        success: "Estimaciones cargadas",
        error: "Error al cargar estimaciones",
      });
    }
  }, [myQuotes]);

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Mis estimaciones</h1>
          <p>Estas son estimaciones de transportistas y estan sujetas a disponibilidad, si deseas confirmar tu flete, porfavor contacta al transportista directamente y el te creara un "Flete" que podras despues ver en "Mis Fletes".</p>
        </Row>
        <hr />

        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <MyOrderTable
            reloadQoutes={getMyQuotes}
            quotes={myQuotes}
            isMobile={isMobile}
          />
        )}
      </Container>
    </>
  );
}
