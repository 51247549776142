import { useState, useEffect } from "react";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import { BedCard } from "./BedCard";
import { simplifyDecimal, formatNumber } from "../../utils";

export default function BedCards({ beds = [], onChange, defaultValue = {} }) {
  const [selectedBed, setSelectedBed] = useState(defaultValue);

  const handleSelectBed = (bed) => {
    setSelectedBed(bed);
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedBed);
    }
  }, [selectedBed]);

  return (
    <div className="cards-select_container">
      <Swiper
        modules={[Navigation]}
        spaceBetween={41}
        slidesPerView={  beds.length > 3 ? 3 : beds.length }
        navigation
        style={{ padding: beds.length > 3 ? "0 40px" : "0 0"}}
      >
        {beds.map((bed, i) => (
          <SwiperSlide key={i}>
            <BedCard
              selected={selectedBed?.id === bed.id}
              title={bed.name}
              description={bed.description}
              maxPallets={bed.max_pallets ?? "N/A"}
              maxWeight={formatNumber(bed.max_weight) + " kg"}
              dimensions={`${simplifyDecimal(bed.max_length)} x ${simplifyDecimal(bed.max_width)} x ${simplifyDecimal(bed.max_height)} m`}
              onClick={() => handleSelectBed(bed)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
