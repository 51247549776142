import React from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Image,
  Modal,
} from "react-bootstrap";
import {
  FaSearchLocation,
  FaPallet,
  FaWarehouse,
  FaRoute,
} from "react-icons/fa";
import { FiLogOut, FiUser } from "react-icons/fi";
import { TiArrowShuffle } from "react-icons/ti";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { useState, useContext } from "react";
import { RiTruckFill, RiMailSendLine, RiGasStationLine } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { MdPayments } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";

import { ReactComponent as Logo } from "../../assets/icons/logo-white.svg";
import userPNG from "../../assets/images/profile-placeholder.jpg";
import ModalSelectCompany from "./ModalSelectCompanyQuick";
import { BodegasContext } from "../../context";
import {
  sessionType,
  companyIdType,
  userIdType,
  carrierType,
} from "../../context/reducers";
import useFetch from "../../hooks/useFetch";

export default function FybNavigationBarDashboard({ navigate_towards, mycompany, updateMyCompany }) {
  const [show, setShow] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [modalSelectCompany, setModalSelectCompany] = useState(false);
  const { fletesAPI } = useFetch();
  const {
    dispSession,
    dispCompanyId,
    dispUserId,
    profile,
    companyId,
    carrier,
  } = useContext(BodegasContext);

  const getCompanies = async () => {
    await fletesAPI
      .get(`api/users/companies/`)
      .then((e) => {
        setCompanies(e.data);
        setModalSelectCompany(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  function collapseOrNot() {
    if (isMobile()) {
      document.getElementById("navigation_bar_dashboard_toggle").click();
    }
  }

  function collapseOrNotProfile() {
    var hideOrNotHide = document.getElementById("dropDown-sun").style.display;
    if (hideOrNotHide == "none") {
      document.getElementById("dropDown-sun").style.display = "block";
    } else {
      document.getElementById("dropDown-sun").style.display = "none";
    }
  }

  return (
    <Navbar className="fyb_dashboard_top_navigation" expand="md">
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo className="fyb_logo_nav" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="fyb_main_top_navigation_toggler"
          id="navigation_bar_dashboard_toggle"
          onClick={() => collapseOrNotProfile()}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto fs-5 px-4 fyb_h4">
            {!carrier && (
              <Nav.Link
                href="#"
                onClick={() => {
                  navigate_towards("quote");
                  collapseOrNot();
                }}
                className="text-light v2_dashboard_navigation_top_link"
              >
                <FaSearchLocation /> Estimación
              </Nav.Link>
            )}
            {!carrier && (
            <Nav.Link
              href="#"
              onClick={() => {
                navigate_towards("my_quotes");
                collapseOrNot();
              }}
              className="text-light v2_dashboard_navigation_top_link"
            >
              <FaPallet /> Mis Estimaciones
            </Nav.Link>
            )}
            <Nav.Link
              href="#"
              onClick={() => {
                navigate_towards("my_orders");
                collapseOrNot();
              }}
              className="text-light v2_dashboard_navigation_top_link"
            >
              <FaPallet /> Mis Fletes
            </Nav.Link>
            {carrier && (
              <Nav.Link
                href="#"
                onClick={() => {
                  navigate_towards("quotes");
                  collapseOrNot();
                }}
                className="text-light v2_dashboard_navigation_top_link"
              >
                <FaPallet /> Estimaciones
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <NavDropdown
          title={
            <>
              <Image
                src={profile.profile_url ? profile.profile_url : userPNG}
                className="dashboard_navigation_top_profile_logo"
              />
            </>
          }
          id="dropDown-sun"
          className="dashboard_navigation_top_dropdown"
        >
          <NavDropdown.Item onClick={() => navigate_towards("company")}>
            <HiOutlineOfficeBuilding /> Mi Compañía
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate_towards("users")}>
            <HiUsers /> Usuarios En Mi Compañía
          </NavDropdown.Item>
          {carrier && (
            <NavDropdown.Item onClick={() => navigate_towards("truckroutes")}>
              <FaRoute /> Mis Rutas
            </NavDropdown.Item>
          )}
          {carrier && (
            <NavDropdown.Item onClick={() => navigate_towards("my_trucks")}>
              <RiTruckFill /> Mis Camiones
            </NavDropdown.Item>
          )}
          <NavDropdown.Item
            onClick={() => {
              getCompanies();
            }}
          >
            <TiArrowShuffle /> Cambiar Compañía
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate_towards("profile")}>
            <FiUser /> Perfil
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              setShow(true);
            }}
          >
            <FiLogOut /> Cerrar Sesión
          </NavDropdown.Item>
          {companyId == "00000000-0000-0000-0000-00000000000f" ? (
            <>
              <hr />
              <NavDropdown.Item onClick={() => navigate_towards("companies")}>
                <HiUsers /> Todas Las Compañías
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate_towards("adminusers")}>
                <HiUsers /> Todas Los Usuarios
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate_towards("adminorders")}>
                <FaPallet /> Todas Las Cargas
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate_towards("admintrucks")}>
                <RiTruckFill /> Todos Los Camiones
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate_towards("adminroutes")}>
                <FaRoute /> Todas Las Rutas
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate_towards("admininvoices")}
              >
                <MdPayments /> Todas las facturas
              </NavDropdown.Item>
            </>
          ) : (
            ""
          )}
        </NavDropdown>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Body>Estas seguro de cerrar sesión?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                dispSession({ type: sessionType.delSession });
                dispCompanyId({ type: companyIdType.delCompanyId });
                dispUserId({ type: userIdType.delUserId });
                window.location.href = "/login";
              }}
            >
              Cerrar Sesión
            </Button>
            <Button variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <ModalSelectCompany
          show={modalSelectCompany}
          setShow={setModalSelectCompany}
          companies={companies}
          updateName={updateMyCompany}
        />
      </Container>
    </Navbar>
  );
}
