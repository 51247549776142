import React, { useState, useEffect, useContext } from "react";
import { BodegasContext } from "../../context";
import { sessionType } from "../../context/reducers/index";
// import { Navigate } from "react-router-dom";
import { Form as Formu, Row, Col, Button, Nav } from "react-bootstrap";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { erroresCodes } from "../../utils";
import { mensajesAPI, mensajesFormulario } from "../../utils/mensajes.js";


//assets
// import { ReactComponent as Logo } from "../../assets/icons/logo-color.svg";

const styles = {
  container: {
    height: "800px",
    background: "#3E85C6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputCont: {
    position: "relative",
    cursor: "pointer",
  },
  inputEye: {
    position: "absolute",
    right: "5px",
    top: "0",
    bottom: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "120px",
  },
};

const loginValidation = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().max(32).required(),
});

export default function Form() {
  const [viewPass, setViewPass] = useState(true);
  const { session, dispSession } = useContext(BodegasContext);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginValidation),
  });
  const onSubmit = (data) => {
    login(data);
  };
  const login = async (data) => {
    await axios
      .post(`${process.env.REACT_APP_URL_API}api/users/login/`, data)
      .then((e) => {
        dispSession({ type: sessionType.setSession, session: e.data.session });
        toast.success('Bienvenido amigo')
        setTimeout(() => {
          window.location.href = "/dashboard";
          reset();
        }, 800)
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error("Error en Fletes & Bodegas, favor de contactar soporte@fletesybodegas.com");
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  useEffect(() => {
    if (Boolean(session)) {
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 400)
    }
  }, [session]);
  return (
    <>
      <Toaster />
      <div style={styles.container}>
        <Formu
          className="bg-light p-4 rounded"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col>
              <h2 className="text-center">Bienvenido!</h2>
            </Col>
          </Row>
          <Row>
            <Col className="my-4">
              <h4 className="text-primary">Iniciar sesión</h4>
            </Col>
          </Row>
          <Row>
                <Formu.Group className="mb-3" controlId="formGroupEmail">
                  <Formu.Label>Correo Electrónico</Formu.Label>
                  <Formu.Control
                    type="text"
                    placeholder="Ingresa tu correo electrónico"
                    {...register("email")}
                    isInvalid={errors.email}
                  />
                  { errors.email && <p className="dashboard_form_error_message"> { mensajesFormulario("email_required") } </p> }
                </Formu.Group>
          </Row>
          <Row>
            <Formu.Group className="mb-3" controlId="formGroupPassword">
              <Formu.Label>Contraseña</Formu.Label>
              <div style={styles.inputCont}>
                <Formu.Control
                  type={viewPass ? "password" : "text"}
                  placeholder="Ingresa tu contraseña"
                  {...register("password")}
                  isInvalid={errors.password}
                />
                <div
                  style={styles.inputEye}
                  onClick={() => setViewPass(!viewPass)}
                >
                  {viewPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                </div>
              </div>
            </Formu.Group>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={0} sm={0}>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <Nav.Link href="/forget-password" className="text-black d-flex justify-content-end">
                <p>Olvidaste tu contraseña?</p>
              </Nav.Link>
          </Col>
          </Row>
          <Row>
            <Col>
              <p>Al presionar el boton de "Iniciar sesión" tu estas aceptando los <br/> <a href="https://app.termly.io/document/terms-of-use-for-saas/f7714dad-7b92-43d4-abae-acc82994f9b1">terminos y condiciones de Fletes y Bodegas </a><br/>asi como <a href="https://app.termly.io/document/privacy-policy/ed1dfc48-4115-4a79-8c26-fc1388e7e265">nuestro aviso de privacidad.</a></p>
              <Button className="text-light w-100" type="submit">
                Iniciar sesión
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-4">
              <p className="d-flex m-0">
                Aun no tienes una cuenta ?
                <Nav.Link href="/register" className="p-0 ms-1">
                  Registrate aqui
                </Nav.Link>
              </p>
            </Col>
          </Row>
        </Formu>
      </div>
    </>
  );
}
