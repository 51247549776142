import { useState, useEffect } from "react";
import { Row, Spinner, Container } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import MyOrdersTable from "./MyOrdersTable";
import RenderSpace from "../../../fyb_components/results/RenderSpace";

const URL_API = process.env.REACT_APP_URL_API;

export default function MyOrders({ carrier }) {
  const [loading, setLoading] = useState(true);
  const [myOrders, setMyOrders] = useState(null);
  const { fletesAPINotURL } = useFetch();

  const fetchOrders = async (url = null) => {
    const finalUrl = url || `${URL_API}api/freight/`;

    setLoading(true);
    try {
      const response = await fletesAPINotURL.get(finalUrl);
      setMyOrders(response.data);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (myOrders === null) {
      toast.promise(fetchOrders(), {
        loading: "Cargando...",
        success: "Fletes cargadas con éxito",
      });
    }
  }, []);
  console.log(myOrders);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Mis Fletes</h1>
          <p>Aqui podras ver los Fletes creados por tus transportistas, podras compartir archivos, communicarte con ellos y localizar el camion (GPS)</p>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : (
          <MyOrdersTable orders={myOrders} reloadOrders={fetchOrders} carrier={carrier} /> 
        )}
      </Container>
    </>
  );
}
