import { useEffect, useState, useContext, } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidation } from "../../../utils/formsValidation"
import useFetch from "../../../hooks/useFetch";
import userPNG from "../../../assets/icons/default-user-icon.png";
import { BodegasContext } from "../../../context";
import { profileType } from '../../../context/reducers'
import toast, { Toaster } from "react-hot-toast";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";
import { FiCheck } from "react-icons/fi";
import RenderProfilePicture from "../../../fyb_components/results/RenderProfilePicture";
import RenderSpace from "../../../fyb_components/results/RenderSpace";


export default function Profile() {
  const [profile, setProfile] = useState(null);
  const { fletesAPI } = useFetch()
  const [loading, setLoading] = useState(false);
  const { userId, dispProfile } = useContext(BodegasContext);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control
  } = useForm({
    resolver: yupResolver(profileValidation),
  });

  const onSubmit = (data) => {
    updateProfile(data);
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/users/${userId}/profile/`);
      setProfile(response.data);
      setValue("first_name", response.data.first_name);
      setValue("last_name", response.data.last_name);
      setValue("phone", response.data.phone);
      setValue("position_title", response.data.position_title);
    } catch (error) {
      console.log(error.response);
      throw error;
    }
    finally {
      setLoading(false);
    }
  };

  const updateProfile = async (data) => {
    await fletesAPI
      .patch(`api/users/${userId}/profile/`, data)
      .then((e) => {
        dispProfile({type: profileType.setProfile ,profile: e.data});
        setProfile(e.data);
        getProfile()
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
    });
  };

  const resendReverify = async () => {
    var data = {}
    data['userId'] = userId
    await fletesAPI
      .post(`api/users/reverify/`, data)
      .then((e) => {
        toast.success(mensajesAPI("reverified_email_sent"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
    });
  };


  useEffect(() => {
    if (!profile) {
      toast.promise(getProfile(), {
        loading: "Cargando...",
        success: "Perfil cargado",
        error: "Error al cargar el perfil",
      });
    }
  }, [profile]);

  return (
    <>
      <Toaster />
      <Container className="mt-5">
      {loading ? (
        <RenderSpace>
          <Spinner animation="border" variant="primary" />
        </RenderSpace>
          ) : ( <>
      <h1 className="dashboard_main_container_h1 fyb_h1 text-center">Perfil</h1><br/>
        <Row className="gutters">
          <Col xl={3} lg={3} md={12} sm={12}>
            <Card className="h-100">
              <Card.Body className="card-body">
                <div className="account-settings">
                  <div className="user-profile">
                    <div className="user-avatar justify-content-center d-flex">
                      <RenderProfilePicture profile={profile} />
                    </div>
                    <br/>
                    <h5 className="text-center">
                      {profile && `${profile.first_name} ${profile.last_name}`}
                    </h5>
                    <h5 className="text-center">
                        {profile && profile.email}
                        {profile && profile.verified ? <FiCheck /> : <><br /><br /><a href="#" onClick={() => resendReverify()}>Re-enviar correo para verificar cuenta</a></>}
                    </h5>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={9} lg={9} md={12} sm={12}>
            <Card className="card h-100">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Card.Body>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <h3 className="fyb_h3 text-center">Datos Personales</h3>
                    </Col>
                  </Row>
                  <Row className="row gutters">
                    <Col className="mb-3" xl={6} lg={6} md={6} sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="first_name">Nombre</Form.Label>
                        <Form.Control
                          {...register("first_name")}
                          isInvalid={errors.first_name}
                          type="text"
                          id="first_name"
                          placeholder="Introduce tu nombre"
                        />
                        { errors.first_name && <p className="dashboard_form_error_message">{mensajesFormulario("first_name_required")}</p> }
                      </Form.Group>
                    </Col>
                    <Col className="mb-3" xl={6} lg={6} md={6} sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="last_name">Apellidos</Form.Label>
                        <Form.Control
                          {...register("last_name")}
                          isInvalid={errors.last_name}
                          type="text"
                          id="last_name"
                          placeholder="Introduce tu apellido"
                        />
                        { errors.last_name && <p className="dashboard_form_error_message">{mensajesFormulario("last_name_required")}</p> }
                      </Form.Group>
                    </Col>
                    <Col className="mb-3" xl={6} lg={6} md={6} sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="phone">Telefono</Form.Label>
                        <PhoneInputWithCountry
                          name="phone"
                          isInvalid={errors.phone}
                          placeholder="Ingrese su número telefónico"
                          defaultCountry="MX"
                          control={control}
                          rules={{ required: true }}
                        />
                        { errors.phone && <p className="dashboard_form_error_message">{mensajesFormulario("phone_invalid_format")}</p> }
                      </Form.Group>
                    </Col>
                    <Col className="mb-3" xl={6} lg={6} md={6} sm={6}>
                      <Form.Group>
                        <Form.Label htmlFor="position_title">
                          Puesto de Trabajo
                        </Form.Label>
                        <Form.Control
                          {...register("position_title")}
                          isInvalid={errors.position_title}
                          type="text"
                          id="position_title"
                          placeholder="Gerente de Compras"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gutters">
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className="d-flex">
                        <Button type="submit" size="sm" className="text-light">
                          Guardar cambios
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
        </>)}
      </Container>
    </>
  );
}
