import React from "react";
import Footer from "../components/Footer";
import FybNavigationBar from "../components/FybNavigationBar";
import { Container, Card } from "react-bootstrap";
import { BsTruck } from "react-icons/bs";

export default function RegisterConfirm() {
  return (
    <>
      <FybNavigationBar />
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: 600 }}>
        <Card style={{width:600}}>
          <Card.Header>
            <h4 className="m-0">Verifica tu correo</h4>
          </Card.Header>
          <Card.Body className="d-flex flex-column justify-content-center align-items-center gap-2">
            <Card.Text>
              Hola, verifica tu cuenta para tener acceso a todas la
              funcionalidad que ofrece Fletes & Bodegas.
            </Card.Text>
            <BsTruck style={{ color: "var(--fyb_darkblue)", fontSize: 80 }} />
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
