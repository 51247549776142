import { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useFetch from "../../../../hooks/useFetch";
import SelectDriver from "../../SelectDriver";
import SelectTruck from "../../SelectTruck";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import { statusOrderLabel, fullNameCity } from "../../../../utils";
import Calendar from "../../MyTrucksById/views/Calendar";

const schemaValidation = yup.object().shape({
  // driver: yup.object().required("Campo requerido").nullable(),
  // truck: yup.object().required("Campo requerido").nullable(),
  status: yup.object().required("Campo requerido").nullable(),
});

const Details = ({ order }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const { fletesAPI } = useFetch();

  const selectedFreight = {
    value: order.id,
    label: `${fullNameCity(order?.quote?.origin_city)} | ${fullNameCity(
      order?.quote?.destination_city
    )} | ${order?.quote?.company?.name}`,
  };
  const defaultTitle = `${order?.quote?.origin_city.name} -> ${order?.quote?.destination_city.name}`;

  const updateDetails = async (data) => {
    try {
      await fletesAPI.patch(`api/freight/${order.id}/`, data);
    } catch (error) {
      console.log(error);
      throw new Error("Error al guardar los cambios");
    }
  };

  const onSubmit = (data) => {
    const new_data = {
      companytruck_id: data.truck.value,
      driver_id: data.driver.value,
      status: data.status.value,
    }

    toast.promise(updateDetails(new_data), {
      loading: "Guardando cambios...",
      success: "Cambios guardados",
      error: "Error al guardar los cambios",
    });
  };

  const watchTruck = watch("truck");

  useEffect(() => {
    if (order) {
      // setValue("driver", { value: order.driver.id, label: order.driver.name });
      // setValue("truck", { value: order.truck.id, label: order.truck.name });
      setValue("status", {
        value: order.status,
        label: statusOrderLabel(order.status),
      });
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="d-flex flex-column gap-4">
            <Row>
              <SelectDriver
                name="driver"
                control={control}
                defaultValue={order.driver}
                error={errors.driver}
                label="Conductor"
                companyId={order?.quote?.transportation_company?.id}
              />
            </Row>
            <Row>
              <SelectTruck
                name="truck"
                control={control}
                defaultValue={order.truck}
                error={errors.truck}
                label="Camión"
                companyId={order?.quote?.transportation_company?.id}
              />
            </Row>
            <Row>
              <RenderSelect
                name="status"
                control={control}
                options={[
                  { value: 1, label: statusOrderLabel(1) },
                  { value: 2, label: statusOrderLabel(2) },
                  { value: 3, label: statusOrderLabel(3) },
                  { value: 4, label: statusOrderLabel(4) },
                ]}
                error={errors.status}
                label="Estado"
              />
            </Row>
          </Col>
        </Row>
      </Form>
      <Row>
        {Boolean(watchTruck) && (
          <Calendar
            trucksData={{ id: watchTruck.value }}
            selected={selectedFreight}
            defaultTitle={defaultTitle}
          />
        )}
      </Row>
    </>
  );
};

export default Details;
